import {endOfWeek, format, isSunday} from 'date-fns';

const dateFormat = 'EEEE d MMM';

export const getWeekToRemove = () => {
  const today = new Date();

  if (isSunday(today)) {
    const todayFormatted = format(today, dateFormat);

    return todayFormatted;
  }

  const mondayIndex = 1;
  const upcomingSunday = endOfWeek(today, {weekStartsOn: mondayIndex});

  const sundayFormatted = format(upcomingSunday, dateFormat);

  return sundayFormatted;
};
